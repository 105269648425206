import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, filter, map, shareReplay, startWith, takeUntil, tap} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ServerDataSource} from '@mominsamir/ngx-smart-table';
import {PrevButtonComponent} from '@theme/components/pager-button/prev-button/prev-button.component';
import {NextButtonComponent} from '@theme/components/pager-button/next-button/next-button.component';

export interface InitFilter {
    field: string;
    value: string;
}

@Component({
    selector: 'ngx-data-table',
    templateUrl: './data-table-component.html',
    styleUrls: ['./data-table-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() settings: any;
    @Input() title: string;
    @Input() subTitle: string;
    @Input() initFilter: InitFilter[] = [];
    @Input() reload: Observable<number>;
    @Input() source: Observable<ServerDataSource> = new BehaviorSubject<ServerDataSource>(null);
    @Output() onCustomEvent = new EventEmitter();
    @Output() onRowSelect = new EventEmitter();
    @Output() onFilterChange = new EventEmitter();
    @Output() downloadFile = new EventEmitter();
    @Output() columnChanges = new EventEmitter();

    public data: ServerDataSource;
    filterColumn: boolean = false;
    allColumnFlag: boolean = true;
    columnList = [];

    public pagingFormGroup = this.fb.group({
        perPage: this.fb.control(8),
    });

    readonly perPage$: Observable<number> = this.perPageFormControl.valueChanges.pipe(
        startWith(8),
        debounceTime(500),
        map((value: number) => {
            let result: number;
            if (value < 1) {
                this.perPageFormControl.setValue(1, {emitEvent: false});
            }
            if (value > 99) {
                this.perPageFormControl.setValue(99, {emitEvent: false});
            }

            if (value > 99) result = 99;
            else if (value < 1) result = 1;
            else result = value;

            return result;
        }),
    );

    _selectedColumnsControl: FormControl = new FormControl([]);

    constructor(protected fb: FormBuilder, private cdr: ChangeDetectorRef) {
        super();
    }

    get selectColumnBtn() {
        return this.settings.selectColumnBtn || null;
    }

    get customButton() {
        return this.settings.customButton || null;
    }

    private get perPageFormControl(): FormControl {
        return this.pagingFormGroup.get('perPage') as FormControl;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.settings?.currentValue) {
            if (this.settings.pager.mode === 'custom') {
                this.settings.pager = {
                    ...this.settings.pager,
                    prevComponent: PrevButtonComponent,
                    nextComponent: NextButtonComponent,
                };
            }
            // Reset records per page based on settings, if defined
            if (this.settings.pager && this.settings.pager.perPage) {
                this.setPerPageFormControl(this.settings.pager.perPage);
            }
        }
    }

    onCustomEventClick($event): void {
        this.onCustomEvent.emit($event);
    }

    refresh = () => {
        if (this.data) this.data.refresh();
    };

    onSearch(query: string = '') {
        Object.keys(this.settings.columns)
            .filter((k) => this.settings.columns[k].type === 'string')
            .map((k) => ({field: k + '_G', search: query}));
    }

    enableHeader() {
        if (this.settings) {
            return this.settings['enableHeader'] === undefined ? true : this.settings['enableHeader'];
        }
    }

    disableGlobalSearch = () => this.settings['enableGlobalSearch'];

    enablePagePerRecord = () =>
        this.settings['enablePagePerRecord'] === undefined ? false : this.settings['enablePagePerRecord'];

    ngOnInit(): void {
        combineLatest<Observable<ServerDataSource>, Observable<number>>([this.source, this.perPage$])
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((dataSource) => {
                    if (dataSource[0] === null) this.data = null;
                }),
                filter((dataSource) => dataSource[0] !== null && dataSource[1] > 0),
                map(([source, perPage]: [ServerDataSource, number]) => {
                    const paging = source.getPaging();
                    if (!paging.page) {
                        source.setPaging(1, perPage, false);
                    } else if (source.getPaging().perPage !== perPage) {
                        // this block executed when per page records are changed.
                        source.setPaging(paging.page, perPage, false);
                    }
                    if (this.initFilter.length > 0) {
                        // this sets initial filters
                        this.initFilter
                            .map((d: InitFilter) => ({field: d.field, search: d.value}))
                            .forEach((x) => source.addFilter(x, false));
                    }
                    return source;
                }),
                debounceTime(500),
                shareReplay(1),
            )
            .subscribe((data) => {
                this.data = data;
                this.cdr.detectChanges();
            });

        this.reload.pipe(filter((data) => data !== null)).subscribe((_) => {
            if (this.data) this.data.refresh();
        });
        if (this.selectColumnBtn) {
            this.settings.selectColumnBtn.columns$
                .pipe(
                    tap((columns: any[]) => {
                        let _visible = columns.filter((_c) => _c.option.visible);
                        this._selectedColumnsControl.setValue(_visible.map((item) => item.option));
                    }),
                )
                .subscribe();
            this._selectedColumnsControl.valueChanges
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((value) => this.columnChanges.emit(value));
        }
    }

    private setPerPageFormControl(val: number) {
        this.perPageFormControl.reset(val);
    }

    enableTableSetting = () =>
        !this.settings['tableSettings'] || this.settings['tableSettings'] === undefined
            ? false
            : this.settings['tableSettings'];

    onUserRowSelect = ($event) => this.onRowSelect.emit($event);

    onFilter($event) {
        this.onFilterChange.emit($event);
    }
}
