// Configuration
export class VisualizationItem {
    value: VisualizationType;
    disabled?: boolean = true;
    children?: Array<VisualizationItem> = [];
}

// Other DTOs

// Enumerations
export enum VisualizationType {
    INDIVIDUAL = 'INDIVIDUAL',
    INDIVIDUAL_CONTOURS = 'INDIVIDUAL_CONTOURS',
    BLENDED = 'BLENDED',
    BLENDED_PRID = 'BLENDED_PRID',
    BLENDED_IRID = 'BLENDED_IRID',
    BLENDED_SECTION = 'BLENDED_SECTION',
    HEATMAP = 'HEATMAP',
    POLYGON = 'POLYGON',
}

// Labels
export const VisualizationTypeLabel = {
    [VisualizationType.INDIVIDUAL]: 'Individual',
    [VisualizationType.INDIVIDUAL_CONTOURS]: 'Indiv. w/ Contours',
    [VisualizationType.BLENDED]: 'Blended',
    [VisualizationType.BLENDED_PRID]: 'Blended - PRID',
    [VisualizationType.BLENDED_IRID]: 'Blended - IRID',
    [VisualizationType.BLENDED_SECTION]: 'Blended - Sections',
    [VisualizationType.HEATMAP]: 'Heatmap',
    [VisualizationType.POLYGON]: 'Polygon',
};
