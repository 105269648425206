import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {map, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FormSection} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {ButtonActionType} from '@theme/components';

@Component({
    selector: 'ngx-form-section',
    templateUrl: './form-section.component.html',
    styleUrls: ['./form-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() sections: FormSection[];
    @Input() continueEvent: number;
    @Input() buttonAction: ButtonActionType;

    @Output() selectionChanged = new EventEmitter<{sectionId: number; subsectionId: number}>();
    form: FormGroup = this.fb.group({
        sectionId: this.fb.control(-1),
        subsectionId: this.fb.control(-1),
    });

    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.form.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                map((value) => {
                    this.selectionChanged.emit(this.form.value);
                }),
            )
            .subscribe();

        // Open first section/subsection by default
        if (this.form.value.sectionId === -1 && this.form.value.subsectionId === -1) {
            this.defaultFistSections();
        }
    }

    defaultFistSections() {
        this.form.setValue({
            sectionId: this.sections[0].id,
            subsectionId: this.sections[0].sections[0]?.id || -1,
        });
    }

    selectSection(sectionId, subsectionId): void {
        this.form.setValue({
            sectionId: sectionId,
            subsectionId: subsectionId,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const {sectionId, subsectionId} = this.form.value;

        const currentSection = this.sections.find((item) => item.id === sectionId);
        const currentSectionIndex = this.sections.findIndex((item) => item.id === sectionId);
        const currentSubSection = currentSection?.sections;
        const currentSubSectionIndex = currentSubSection?.findIndex((item) => item.id === subsectionId);

        const movedSubSectionIndex =
            currentSubSection?.findIndex((item) => item.id === this.moveAction(subsectionId)) === -1
                ? this.moveAction(currentSubSectionIndex)
                : currentSubSection?.findIndex((item) => item.id === this.moveAction(subsectionId));

        const startOfSection = 0 === movedSubSectionIndex;
        const endOfSubSection = movedSubSectionIndex === currentSubSection?.length;
        if (this.form.value.sectionId !== -1 && this.form.value.subsectionId !== -1) {
            //children section selected
            if (currentSubSection.length - 1 >= currentSubSectionIndex && !endOfSubSection && !startOfSection) {
                this.form.setValue({
                    sectionId: currentSection.id,
                    subsectionId: currentSubSection[movedSubSectionIndex].id,
                });
            } else if (endOfSubSection && this.buttonAction === ButtonActionType.Next) {
                this.form.setValue({
                    sectionId: this.sections[this.moveAction(currentSectionIndex)].id,
                    subsectionId: this.sections[this.moveAction(currentSectionIndex)].sections[0]?.id,
                });
            } else if (startOfSection && this.buttonAction === ButtonActionType.Prev) {
                this.handlePreviousSectionNavigation(currentSectionIndex, currentSubSectionIndex);
            }
        } else if (this.form.value.sectionId !== -1 && this.form.value.subsectionId === -1) {
            //  code for level 1
            this.form.setValue({
                sectionId: this.sections[this.moveAction(currentSectionIndex)].id,
                subsectionId: -1,
            });
        }
    }
    private handlePreviousSectionNavigation(currentSectionIndex: number, currentSubSectionIndex: number): void {
        let nextSectionIndex =
            currentSubSectionIndex === 0 ? this.moveAction(currentSectionIndex) : currentSectionIndex;
        const sectionList = this.sections[nextSectionIndex].sections;
        const subSectionId =
            currentSectionIndex !== 0 && currentSubSectionIndex === 0
                ? sectionList[sectionList.length - 1]?.id
                : sectionList[0]?.id;

        this.form.setValue({
            sectionId: this.sections[nextSectionIndex].id,
            subsectionId: subSectionId,
        });
    }
    private moveAction(value) {
        if (this.buttonAction === ButtonActionType.Next) {
            return value + 1;
        } else if (this.buttonAction === ButtonActionType.Prev) {
            return value === 0 ? 0 : value - 1;
        }
    }
}
