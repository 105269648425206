import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Injector,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {Router} from '@angular/router';
import {MsalService, MsalBroadcastService} from '@azure/msal-angular';
import {AuthenticationResult, InteractionStatus} from '@azure/msal-browser';
import {AuthService} from '@core/interfaces/common/auth';
import {User, UsersService} from '@core/interfaces/common/users';
import {
    NB_AUTH_OPTIONS,
    NbAuthOAuth2Token,
    NbAuthResult,
    NbAuthService,
    NbLoginComponent,
    NbTokenService,
} from '@nebular/auth';
import {combineLatest, Observable, of, Subject, timer} from 'rxjs';
import {debounceTime, filter, takeUntil, tap} from 'rxjs/operators';
import {UsersStore} from '@store/common/users.store';
import {StudiesStore} from '@store/common/studies.store';
import {NbToastrService} from '@nebular/theme';
import {environment} from '../../../../environments/environment';
import {FormControl} from '@angular/forms';

/**
 * Login
 * - Pre-auth (no login required)
 * - User enters this page to start login process.
 */
@Component({
    selector: 'ngx-auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends NbLoginComponent implements OnInit, OnDestroy {
    public infoToastr;
    public requestsPending$ = this.studiesStore.requestsPending$.pipe(
        tap((pending) => {
            if (pending && !this.infoToastr)
                this.infoToastr = this.toastrService.info(
                    'Completing the logout process of the previous user, this should only take a moment. Please do not leave this page...',
                    'Please wait.',
                );
            else if (!pending && this.infoToastr?.isOpen) this.infoToastr?.close();
        }),
    );
    public ssoOptionsEnabled = false;
    public isIframe = false;
    public sensitiveInfoLogin: boolean = false;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        protected service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) options: {},
        cd: ChangeDetectorRef,
        router: Router,
        private userService: UsersService,
        private usersStore: UsersStore,
        private studiesStore: StudiesStore,
        private msalAuthService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private authService: AuthService,
        private injector: Injector,
        private toastrService: NbToastrService,
    ) {
        super(service, options, cd, router);
    }

    userNameInput = new FormControl('');
    passwordInput = new FormControl('');

    ngOnInit(): void {
        timer(100).subscribe(() => {
            this.userNameInput.setValue('', {emitEvent: false});
        });
        combineLatest([this.userNameInput.valueChanges, this.passwordInput.valueChanges])
            .pipe(takeUntil(this._destroying$))
            .subscribe(([userName, password]) => {
                this.user.username = userName;
                this.user.password = password;
            });
        this.ssoOptionsEnabled = environment?.SSO_ENABLED;
        this.sensitiveInfoLogin = environment?.SENSITIVE_INFO_LOGIN;
        this.isIframe = window !== window.parent && !window.opener;
        this.msalBroadcastService.msalSubject$.pipe(takeUntil(this._destroying$)).subscribe((event) => {
            this.ssoCustomErrorMessage(event.eventType.replace('msal:', ''));
        });
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$),
            )
            .subscribe();
    }

    ssoAuthenticate() {
        this.userNameInput.reset();
        this.passwordInput.reset();

        this.msalAuthService.loginPopup().subscribe({
            next: (result: AuthenticationResult) => {
                this.authService.validateSSOTokenAzureAD(result).subscribe((resp) => {
                    const token = new NbAuthOAuth2Token(resp, 'email');
                    this.nbTokenService.set(token);
                    this.setUser(
                        new NbAuthResult(true, resp, '/', [], 'You have been successfully authenticated.', token),
                    );
                    this.cd.detectChanges();
                });
            },
            error: (error) => (this.errors = [error]),
        });
    }

    login(): void {
        this.errors = [];
        this.messages = [];
        this.submitted = true;

        let param = {
            email: this.user.username,
            password: this.user.password,
        };

        this.service.authenticate(this.strategy, param).subscribe((result: NbAuthResult) => {
            this.submitted = false;
            localStorage.setItem('fromLogin', JSON.stringify(true));

            if (result.isSuccess()) {
                this.messages = result.getMessages();
                this.setUser(result);
            } else {
                this.errors = result.getErrors();
            }

            this.cd.detectChanges();
        });
    }

    setUser(result: NbAuthResult) {
        this.userService
            .getCurrentUser()
            .pipe(filter((user: User) => user != null))
            .subscribe((user: User) => {
                if (user.passwordExpired) {
                    this.usersStore.setUser(user);
                    this.router.navigate(['/auth/update-password']);
                } else if (user.tenants.length > 1) {
                    this.router.navigate(['/auth/select-organization']);
                } else if (this.sensitiveInfoLogin) {
                    this.router.navigate(['/auth/sensitive-info-disclosure']);
                } else if (result.getRedirect()) {
                    setTimeout(() => {
                        return this.router.navigateByUrl(result.getRedirect());
                    }, this.redirectDelay);
                }
            });
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    protected get nbTokenService(): NbTokenService {
        return this.injector.get(NbTokenService);
    }

    resetState() {
        this.showMessages = {
            success: true,
            error: true,
        };
        this.errors = [];
        this.messages = [];
        this.cd.detectChanges();
    }

    inputBoxTouchEvent() {
        if (this.userNameInput.pristine && this.passwordInput.pristine) {
            this.resetState();
        }
    }

    ssoCustomErrorMessage(event) {
        switch (event) {
            case 'loginFailure':
                this.errors = ['Single Sign-On window closed. You can try again or use the standard login.'];
                break;
            case 'loginStart':
                this.resetState();
                break;
            default:
                break;
        }
        this.cd.detectChanges();
    }
}
