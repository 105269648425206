/*
 * Series / chart support
 */

import {Observable} from 'rxjs';

export class SimpleSeriesScatter {
    code: string;
    legend: string;
    data: [any: any];
    unit: SimpleUnit;
}

export class SimpleTrendData {
    status: string;
    value: number;
    unit: SimpleUnit;
}

export class SimpleUnit {
    prefix: string;
    suffix: string;
    symbol: string;

    constructor() {
        this.prefix = '';
        this.suffix = '';
        this.symbol = '';
    }
}

export class PagingResponse<T> {
    items: T[];
    pageNumber: number;
    pageSize: number;
    startRecordNumber: number;
    totalCount: number;
    totalFilteredRecords: number;
}

export interface DataTableParam {
    pageNumber: number;
    pageSize: number;
    filterParams: Filter[];
    sortOrderMap: Record<string, 'asc' | 'desc'>;
}

/*
 * Series / data
 */
export interface SimpleData<T> {
    code?: string;
    legend: string;
    data: T[];
    unit?: SimpleUnit;
}
export interface SimpleSeries<X, Y> {
    code?: string;
    legend: string;
    labels: X[];
    data: Y[];
    unit?: SimpleUnit;
}
export interface SimpleMultiSeries<X> {
    labels: X[];
    series: SimpleData<any>[];
}

// Series, thresholding
export interface ThresholdStatic {
    code?: string;
    legend: string;
    value: number;
    unit?: SimpleUnit;
}
export interface ThresholdStep {
    code?: string;
    legend: string;
    steps: {start: any; value: any}[];
    unit?: SimpleUnit;
}
export interface ThresholdLinear {
    code?: string;
    legend: string;
    values: {x: any; y: any}[];
    unit?: SimpleUnit;
}

export interface APIResponse<T> {
    [x: string]: any;

    response: T;
    status: number;
    message: string;
}
export interface PresignedURL {
    bucketName: string;
    fileKey: string;
    url: string;
}

export interface DownloadLink {
    link: string;
}

/*
 * Filtering
 */
// Generic filter object, generally SENT to APIs
export interface Filter {
    fieldKey: string;
    fieldName?: string;
    operator: FilterOperatorType;
    fieldType?: FilterFieldType;
    value?: any;
    values?: any[];
    // FilterFieldType.STRING
    options?: FilterFieldOption[];
    //for UI usage, doesn't come from api
    loading?: boolean;
}
// Generic filter config for multiple operators per field, i.e., RECEIVE from APIs
export interface TableFilterConfig {
    fieldGroup?: string;
    fieldName?: string;
    fieldKey: string;
    fieldType: FilterFieldType;
    options?: FilterFieldOption[];
    fieldOperatorOptions: FilterOperatorOption[];
}
export interface FilterOperatorOption {
    filterOperator: FilterOperatorType;
    operatorLabel: string;
    filterType: FilterType;
    value?: any;
    values?: any[];
}

export interface FilterFieldOption {
    key: string;
    name: string;
}

export interface FilterFieldOptionRequest {
    fieldKey: string;
    searchString?: string;
    filterParams?: Filter[];
}

export interface FilterFieldOptionResponse {
    totalCount: number;
    data: FilterFieldOption[];
}

export interface FiltersDynamicOptionsStore {
    [field: string]: FilterDynamicOption;
}

export interface FilterDynamicOption {
    scope: Filter[];
    options: Observable<FilterFieldOption[]>;
}

// Enums
export enum FilterOperatorType {
    EQUAL = 'EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
    CONTAIN = 'CONTAIN',
    DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
    STARTS_WITH = 'STARTS_WITH',
    ENDS_WITH = 'ENDS_WITH',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
    LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
    BETWEEN = 'BETWEEN',
    IN = 'IN',
    NOT_IN = 'NOT_IN',
    IS_EMPTY = 'IS_EMPTY',
    NOT_EMPTY = 'NOT_EMPTY',
}
export enum FilterFieldType {
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    DOUBLE = 'DOUBLE',
    LONG = 'LONG',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    BOOLEAN = 'BOOLEAN',
    DATE_RANGE = 'DATE_RANGE',
    DATETIME_RANGE = 'DATETIME_RANGE',
}

export namespace FilterFieldType {
    export function isNumericFieldType(fieldType: FilterFieldType): boolean {
        switch (fieldType) {
            case FilterFieldType.INTEGER:
            case FilterFieldType.DOUBLE:
            case FilterFieldType.LONG:
                return true;
            default:
                return false;
        }
    }
}
export enum FilterType {
    None = 'NONE',
    Numeric = 'NUMERIC',
    NumericRange = 'NUMERIC_RANGE',
    FreeText = 'TEXT',
    Date = 'DATE',
    DateRange = 'DATE_RANGE',
    SingleSelect = 'SINGLE_SELECT',
    MultiSelect = 'MULTI_SELECT',
    MultiSelectAutocomplete = 'MULTI_SELECT_AUTOCOMPLETE',
}

export enum FieldType {
    Numeric = 'NUMERIC',
    Signature = 'SIGNATURE',
    Text = 'TEXT',
    Issues = 'ISSUES',
    Checkbox = 'CHECKBOX',
    SingleSelect = 'SINGLE_SELECT',
    MultiSelect = 'MULTI_SELECT',
    Image = 'IMAGE',
}
export interface GeneralOption {
    label: string;
    value: string;
}
