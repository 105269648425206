import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, filter, map, takeUntil} from 'rxjs/operators';
import {
    FormCellType,
    FormField,
    FormViewModeType,
    NumericFieldResultDto,
    NumericType,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {FormFieldBaseComponent} from '../base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';

@Component({
    selector: 'ngx-form-field-numeric',
    templateUrl: './numeric.component.html',
    styleUrls: ['./numeric.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericComponent extends FormFieldBaseComponent<NumericFieldResultDto> implements OnInit {
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    public FormMode = FormMode;
    public minValue = -99999;
    public maxValue = 99999;
    public pattern: RegExp = /[^0-9]/g;

    constructor(private cd: ChangeDetectorRef) {
        super();
    }
    ngOnInit(): void {
        this.maxValue = this.field?.range?.max;
        this.minValue = this.field?.range?.min;
        if (this.field?.range?.valueType == NumericType.INTEGER) {
            this.pattern = /[^0-9]/g;
        } else if (this.field?.range?.valueType == NumericType.DOUBLE) {
            this.pattern = /[^0-9.]/g;
        }

        this.genericInit();
        this.checkValidation
            .pipe(
                takeUntil(this.unsubscribe$),
                map((value) => {
                    if (!value && (this.fieldForm?.hasError('max') || this.fieldForm?.hasError('min'))) {
                        this.fieldForm?.markAsTouched();
                        this.fieldForm?.setErrors({required: true});
                    } else if (value && !this.fieldForm?.value && this.required) {
                        this.fieldForm?.markAsTouched();
                        this.fieldForm?.setErrors({required: true});
                    }
                    this.cd.detectChanges();
                }),
            )
            .subscribe();

        if (this.fieldForm) {
            const currentValidators = this.fieldForm.validator ? [this.fieldForm.validator] : [];
            this.fieldForm.setValidators([
                ...currentValidators,
                Validators.pattern(this.pattern),
                Validators.max(this.maxValue),
                Validators.min(this.minValue),
            ]);
            this.fieldForm.updateValueAndValidity({emitEvent: false});

            this.fieldForm.valueChanges
                .pipe(
                    takeUntil(this.unsubscribe$),
                    debounceTime(1000),
                    filter((value) => !!value),
                    map((value) => {
                        if (value) {
                            this.minMaxValidation(value);
                        }
                    }),
                )
                .subscribe();
        }
    }

    private minMaxValidation(value): void {
        if (this.maxValue < value) {
            this.fieldForm?.setErrors({max: true, required: true});
        }
        if (this.minValue > value) {
            this.fieldForm.setErrors({min: true, required: true});
        }
        this.fieldForm?.markAsTouched();
        this.cd.detectChanges();
    }

    public getValueOrDefault(defaultValue: string): string | number {
        return this.currentValue ? this.currentValue.value : defaultValue;
    }

    /*
     * Implement abstract methods
     */
    validate(value: NumericFieldResultDto): boolean {
        if (this.required && value.value == null) {
            return false;
        }

        if (value.value >= this.field.range?.min && value.value <= this.field.range?.max) {
            return true;
        }

        return !this.required;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormControl;
    }

    applyValueChange(item: any): NumericFieldResultDto {
        return {
            ...this.currentValue,
            value: item,
        };
    }

    getFormValue(): any {
        return this.result ? this.result.value : '';
    }
    validateInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const sanitizedValue = inputElement.value.replace(this.pattern, '');
        inputElement.value = sanitizedValue;
        this.fieldForm.setValue(sanitizedValue, {emitEvent: false});
    }
}
