<div class="collapsed-container">
    <div  #scrollContainer class="fields-container" [class.collapsed]="!isExpanded">
        <ngx-filter-field
            *ngFor="let item of filterControl.value; let i = index"
            [filter]="item"
            (updateOptions)="onUpdateOptions($event)"
            [selected]="getSelectedValue(item?.fieldKey)"
            [clearFilter$]="clearFilter$"
            (valueChange)="onValueChange($event)"
            [options$]="filtersDynamicOptions[item?.fieldKey]?.options"
        ></ngx-filter-field>
    </div>

    <button
        *ngIf="filterControl.value?.length"
        class="expand-btn"
        (click)="toggleExpanded()"
        nbButton
        status="control"
        size="tiny"
    >
        <nb-icon [icon]="isExpanded ? 'arrow-up' : 'arrow-down'"></nb-icon>
        {{ isExpanded ? "show Less" : "show more" }}
    </button>
</div>

<div class="btn-container">
    <ngx-button-multiselect
        *ngIf="_filterOptions$ | async"
        [formControl]="filterControl"
        [iconDetails]="{icon: 'plus-outline'}"
        [options$]="_filterOptions$"
        (optionChange)="unselectOption($event)"
        btnTitle="add filter"
        defaultGroupTitle="Filters"
        status="primary"
    ></ngx-button-multiselect>

    <button (click)="resetFilters()" [disabled]="!filterControl.value?.length" nbButton size="medium" status="basic">
        <nb-icon icon="close-outline"></nb-icon>
        clear filters
    </button>
</div>
