import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, filter, map, shareReplay, startWith, tap} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DataAuditPopoutSettings, PopoutService} from '@core/interfaces/common/popout';
import {DataAuditRequest} from '@core/interfaces/engin/data-audit/asset-data-audit';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';

@Injectable()
export class DataAuditPopoutStore {
    public popoutFormGroup = this.fb.group({});

    private popoutChangeSubscription: Subscription;
    private searchSubscription: Subscription;

    private popoutSettings = new BehaviorSubject<DataAuditPopoutSettings>(null);
    private selectedFilter = new BehaviorSubject<any>(null);
    private aviodFirstLoading = new BehaviorSubject<number>(0);
    readonly popoutSettings$: Observable<DataAuditPopoutSettings> = this.popoutSettings.asObservable().pipe(
        filter((settings) => settings != null),
        tap((settings) => {
            this.popoutChangeSubscription && this.popoutChangeSubscription.unsubscribe();
            this.searchSubscription && this.searchSubscription.unsubscribe();
            this.initPopout(settings);
        }),
        shareReplay(1),
    );

    private popoutFormGroupValue = new BehaviorSubject(null);
    readonly currentPopout$: Observable<DataAuditRequest> = combineLatest<
        Observable<any>,
        Observable<DataAuditPopoutSettings>
    >([this.popoutFormGroupValue.asObservable(), this.popoutSettings$]).pipe(
        filter(([value, settings]) => value !== null),
        map(([value, settings]) => {
            return this.mapFormGroupValue(value, settings);
        }),
    );

    constructor(
        private popoutData: PopoutService,
        private fb: FormBuilder,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {}

    /*
     * Update the popout to select one single data load: the most recent timestamp
     * - If a single data load is currently selected, leave that single selection
     */
    public updatePopoutSingleDataLoad() {
        let newDlFilters = this.filtersFormGroup.value.data_load_code;
        const dlSelected = newDlFilters.filter((dl) => dl.selected).length;
        if (dlSelected === 1) {
            // nothing
        } else {
            let mostRecentTimestamp = 0;
            newDlFilters.forEach((dl) => {
                const dlEpoch = dl.code;
                if (mostRecentTimestamp === 0 || dlEpoch > mostRecentTimestamp) {
                    mostRecentTimestamp = dlEpoch;
                }
            });
            newDlFilters = newDlFilters.map((dl) => {
                if (dl.code === mostRecentTimestamp) {
                    return {
                        ...dl,
                        selected: true,
                    };
                } else {
                    return {
                        ...dl,
                        selected: false,
                    };
                }
            });
            this.filtersFormGroup.patchValue({data_load_code: newDlFilters});
        }
    }

    // Update the popout to unselect any data loads
    public updatePopoutClearDataLoad() {
        let newDlFilters = this.filtersFormGroup.value.data_load_code;
        newDlFilters = newDlFilters.map((dl) => {
            return {
                ...dl,
                selected: false,
            };
        });
        this.filtersFormGroup.patchValue({data_load_code: newDlFilters});
    }

    get filtersChanged(): boolean {
        if (this.filtersFormGroup) {
            const filtersValue = this.filtersFormGroup.value;
            const props = Object.keys(filtersValue).filter((prop) => prop.includes('_selected'));
            const item = props.find((prop) => filtersValue[prop] !== 'All');
            return item !== undefined;
        }
        return true;
    }

    get filtersFormGroup(): FormGroup {
        return this.popoutFormGroup.get('filters') as FormGroup;
    }

    get filtersFormGroupControls(): any {
        return this.filtersFormGroup.controls;
    }

    get searchStringFormControl(): FormControl {
        return this.popoutFormGroup.get('searchString') as FormControl;
    }

    public loadPopoutSettings() {
        this.popoutData.getDataAuditToolPopoutSettings().subscribe((settings) => {
            this.updateSettings(settings);
        });
    }

    public destroy() {
        this.popoutChangeSubscription && this.popoutChangeSubscription.unsubscribe();
        this.searchSubscription && this.searchSubscription.unsubscribe();
        this.popoutFormGroup = this.fb.group({});
    }

    public updateSettings(settings: DataAuditPopoutSettings, settingName?: string) {
        let resultSettings: DataAuditPopoutSettings = settings;
        if (settingName) {
            resultSettings = {
                ...this.popoutSettings.value,
                [settingName]: settings[settingName].map((item) => {
                    return {
                        ...item,
                    };
                }),
            };
        }
        this.popoutSettings.next(resultSettings);
    }

    private setFiltersFormGroup(group: FormGroup) {
        if (this.filtersFormGroup) {
            this.filtersFormGroup.reset(group.value);
            Object.keys(group.value).forEach((prop) => {
                this.filtersFormGroup.controls[prop].enable();
            });
        } else {
            this.popoutFormGroup.setControl('filters', group);
        }
    }

    private setSearchStringFormControl(search: FormControl) {
        if (this.searchStringFormControl) {
            this.searchStringFormControl.reset(search.value);
        } else {
            this.popoutFormGroup.setControl('searchString', search);
        }
    }

    private updateTotals() {
        const properties = Object.keys(this.filtersFormGroupControls).filter(
            (propName) => !propName.includes('_selected'),
        );

        properties.forEach((prop) => {
            if (this.filtersFormGroupControls[prop].value) {
                const control = this.filtersFormGroupControls[`${prop}_selected`];
                const selected = this.filtersFormGroupControls[prop].value.filter((item) => item.selected).length;
                const all = this.filtersFormGroupControls[prop].value.length;
                control.setValue(selected === all ? 'All' : selected, {emitEvent: false});
            }
        });
    }

    private search(searchString: string) {
        /*
         * Compare search string to all filter options
         * - Compare by "code" and "name" property concatenated
         * - Update panel control groups to show only these matching values
         */
        const props = Object.keys(this.filtersFormGroupControls).filter((prop) => !prop.includes('_selected'));
        const filters = {};
        props.forEach((propName) => {
            if (this.filtersFormGroupControls[propName].value) {
                filters[propName] = this.filtersFormGroupControls[propName].value.map((item) => {
                    return {
                        ...item,
                        enabled: `${item.code}${item.name}`.toLowerCase().includes(searchString.toLowerCase()),
                    };
                });
            }
        });
        this.filtersFormGroup.patchValue(filters, {
            emitEvent: false,
            onlySelf: true,
        });

        /*
         * Compare "input" (search string, split by comma) to all filter options
         * - Compare by "code" and "name" property concatenated
         * - For any match found, set filter selected to true to expand in control panel
         */
        const selectedFilter = [];
        if (searchString.length > 0) {
            Object.entries(this.filtersFormGroup.value).map((k: [string, unknown]) => {
                const dataTotal = {
                    ...(k[1] as object),
                    name: k[0],
                };
                Object.values(dataTotal).map((x) => {
                    if (x['name'] !== undefined && x['code'] !== undefined) {
                        const name = x['name'].toString().toLowerCase();
                        const code = x['code'].toString().toLowerCase();
                        if (`${code}${name}`.includes(searchString.toLowerCase())) {
                            x['enabled'] = true;
                            selectedFilter.push(dataTotal['name']);
                        }
                    }
                });
            });

            this.selectedFilter.next(selectedFilter.filter(this.onlyUniqueValue));
            this.popoutSettings.value.filters.map((filterValue) => {
                filterValue['selected'] = !!this.selectedFilter.value.includes(filterValue.code);
            });
        } else {
            this.popoutSettings.value.filters.map((filterValue) => {
                filterValue['selected'] = false;
            });
        }
    }

    onlyUniqueValue(value, index, self) {
        return self.indexOf(value) === index;
    }

    private subscribeOnChanges() {
        this.popoutChangeSubscription = combineLatest<Observable<any>>([
            this.filtersFormGroup.valueChanges.pipe(
                startWith({...this.filtersFormGroup.value}),
                tap(() => this.updateTotals()),
                debounceTime(500),
            ),
        ])
            .pipe(
                map(([filters]) => {
                    return {
                        filters: filters,
                    };
                }),
                tap((value) => {
                    if (this.popoutFormGroupValue.value === null) {
                        this.popoutFormGroupValue.next(value);
                    }
                }),
                debounceTime(1500),
            )
            .subscribe((value) => {
                this.aviodFirstLoading.next(this.aviodFirstLoading.getValue() + 1);
                if (this.aviodFirstLoading.value > 1) {
                    this.usageAnalyticsService.logView('Data Audit Control Panel');
                    this.popoutFormGroupValue.next(value);
                }
                this.updatePopoutSettingsValue(value);
            });

        this.searchSubscription = this.searchStringFormControl.valueChanges
            .pipe(debounceTime(200))
            .subscribe((searchString) => this.search(searchString));
    }

    private updatePopoutSettingsValue(next: any) {
        // Update filters
        this.popoutSettings.value.filters.forEach((item) => {
            if (next.filters[item.code] !== null && next.filters[item.code] !== undefined) {
                item.enabled = true;
                item.options = next.filters[item.code];
            } else {
                item.enabled = false;
            }
        });
    }

    private mapFormGroupValue(popoutValue: any, settings: DataAuditPopoutSettings): DataAuditRequest {
        // Filters
        const filterList = [];
        const filters = Object.keys(popoutValue.filters).filter(
            (prop) => !prop.includes('_selected') && popoutValue.filters[prop],
        );
        filters.forEach((item) => {
            const selectedOptions = popoutValue.filters[item]
                .filter((option) => !!option.selected)
                .map((option) => option.name);
            if (selectedOptions?.length) {
                filterList.push({
                    fieldKey: item,
                    fieldType: 'STRING',
                    operator: 'IN',
                    values: selectedOptions,
                });
            }
        });
        return new DataAuditRequest(filterList);
    }

    private initPopout(settings: DataAuditPopoutSettings) {
        this.initFilters(settings.filters);
        this.setSearchStringFormControl(this.fb.control(''));
        this.subscribeOnChanges();
    }

    private initFilters(initialFilters: any[]) {
        const filters = this.fb.group({});
        initialFilters.forEach((item) => {
            filters.setControl(
                `${item.code}_selected`,
                this.fb.control({
                    value: 0,
                    disabled: true,
                }),
            );
            filters.setControl(item.code, this.fb.control({value: item.options, disabled: !item.enabled}));
        });
        this.setFiltersFormGroup(filters);
    }
}
