import {Observable} from 'rxjs';
import {ProbabilityTypeEnum} from '@core/interfaces/engin/analyzer';
import {APIResponse} from '@core/interfaces/system/system-common';

// Optimizer request for main analysis
export class OptimizerAnalysisRequest {
    isConstrained: boolean;
    applySystemDropdown: boolean;
    stateChanged: boolean;
    sensitivityStudy: {
        studyId: number;
        userId: string;
        currentYear: number;
        evaluationPeriod: number;
        sensitivityParams: [StringSet];
    };
    longtermPlanningMethod: number;
    sliders: {
        dropdown: string;
        sliders: {
            slider: string;
            values: number[];
            isUnitMonetary: boolean;
        }[];
    }[];

    constructor(
        isConstrained: boolean,
        applySystemDropdown: boolean,
        stateChanged: boolean,
        sliders: any,
        longtermPlanningMethod: number,
    ) {
        const defaultPlanningMethod = 2;
        this.isConstrained = isConstrained;
        this.applySystemDropdown = applySystemDropdown;
        this.stateChanged = stateChanged;
        this.sliders = sliders;
        this.sensitivityStudy = null;
        this.longtermPlanningMethod = longtermPlanningMethod ? longtermPlanningMethod : defaultPlanningMethod;
    }
}

// Optimizer request for charts
export class OptimizerChartRequest {
    isConstrained: boolean;
    sensitivityStudy: {
        studyId: number;
        userId: string;
        currentYear: number;
        evaluationPeriod: number;
        sensitivityParams: [StringSet];
    };
    graphList: {
        graphId: string;
        unit: string;
    }[];
    probabilityType: ProbabilityTypeEnum;

    constructor() {}
}

// Generic chart response object contains data for constrained and unconstrained views
export interface OptimizerChartResponse {
    constrained: ClusteredSeries[];
    unconstrained: ClusteredSeries[];
}

export interface ClusteredSeries {
    code: string;
    name: string;
    graphTitle: string;
    graphId: string;
    legend: string[];
    labels: string[];
    data: number[][];
    unit: Unit;
}

export interface Unit {
    prefix: string[];
    suffix: string[];
    symbol: string[];
}

// Specific chart response for reporting summary KPIs
export interface OptimizerKpiResponse {
    constrained: KpiDataResp[];
    unconstrained: KpiDataResp[];
}

export interface KpiDataResp {
    title: string;
    subtitle: string;
    unit: string;
    residualValue: number;
    delta: number;
}

// Common
export interface StringSet {
    [param: string]: string;
}

export abstract class OptimizerService {
    abstract checkOptimizerResultsReady(request: OptimizerAnalysisRequest): Observable<APIResponse<boolean>>;

    abstract getChartDataSummarySpending(
        request: OptimizerChartRequest,
    ): Observable<APIResponse<OptimizerChartResponse>>;

    abstract getChartDataSummaryOutcomes(request: OptimizerChartRequest): Observable<APIResponse<OptimizerKpiResponse>>;

    abstract getChartDataProactiveForecast(
        request: OptimizerChartRequest,
    ): Observable<APIResponse<OptimizerChartResponse>>;

    abstract getChartDataReactiveForecast(
        request: OptimizerChartRequest,
    ): Observable<APIResponse<OptimizerChartResponse>>;

    abstract getChartDataFailureForecast(
        request: OptimizerChartRequest,
    ): Observable<APIResponse<OptimizerChartResponse>>;

    abstract getChartDataOutageForecast(
        request: OptimizerChartRequest,
    ): Observable<APIResponse<OptimizerChartResponse>>;

    abstract getChartDataRiskForecastByImpact(
        request: OptimizerChartRequest,
    ): Observable<APIResponse<OptimizerChartResponse>>;

    abstract getChartDataRiskForecastByCategory(
        request: OptimizerChartRequest,
    ): Observable<APIResponse<OptimizerChartResponse>>;
}
