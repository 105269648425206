import {Observable} from 'rxjs';
import {
    APIResponse,
    DataTableParam,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    EconomicsOverTimeResponse,
    Project,
    ProjectCostAnalysis,
    ProjectCreate, ProjectDto,
    ProjectUpdate,
} from '../../../../pages/geospatial-viewer/model/project';

export enum Modes {
    CREATE = 'create',
    EDIT = 'edit',
}

export interface AssetListDto {
    assetId: string;
    assetClass: string;
    assetSubClass: string;
    region: string;
    station: string;
    replacementCost: number;
    assetClassCode: string;
}

export abstract class PmProjectService {
    // Project
    abstract getProjectById(projectId: number): Observable<APIResponse<Project>>;

    abstract createProject(req: ProjectCreate): Observable<APIResponse<Project>>;

    abstract updateProject(req: ProjectUpdate): Observable<APIResponse<Project>>;

    abstract deleteProject(projectId: number): Observable<APIResponse<boolean>>;

    abstract getProjectList(params: Filter[]): Observable<Project[]>;

    abstract getAllProjectsByProgramId(programId: number): Observable<Project[]>;

    // Project DTO
    abstract getProjectDtoById(projectId: number, workflowItemId: number): Observable<APIResponse<ProjectDto>>;

    abstract getProjectDtoListDataSource(workflowItemId: number): Observable<DataSource>

    abstract downloadCsvProjectDtoList(workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>>;

    abstract downloadCsvProjectDtoListByProgramId(workflowItemId: number, programId: number): Observable<APIResponse<DownloadCSVUrl>>;

    abstract downloadCsvProjectDtoListBySegmentId(workflowItemId: number, segmentId: number): Observable<APIResponse<DownloadCSVUrl>>;

    // Other project support
    abstract getAssetScopeFilters(): Observable<APIResponse<TableFilterConfig[]>>;

    abstract getAssetFilterFieldOptions(
        workflowItemId: number,
        filterOption: FilterFieldOptionRequest,
    ): Observable<APIResponse<FilterFieldOptionResponse>>;

    abstract getAssetListMatchingScope(workflowItemId: number, filterParams: Filter[]): Observable<DataSource>;

    abstract downloadCsvAssetListMatchingScope(
        workflowItemId: number,
        filters: DataTableParam,
    ): Observable<APIResponse<DownloadCSVUrl>>;

    abstract projectNameExistsValidator(projectName: string): Observable<APIResponse<boolean>>;

    // Project details (and project assets)
    abstract getProjectAssetsList(projectId: number, workflowItemId: number): Observable<DataSource>;

    abstract downloadCsvProjectAssetsList(
        projectId: number,
        workflowItemId: number,
    ): Observable<APIResponse<DownloadCSVUrl>>;

    abstract getEconomicsOverTime(id: number, year: number): Observable<APIResponse<EconomicsOverTimeResponse>>;

    abstract findProjectCostAnalysis(id: number): Observable<APIResponse<ProjectCostAnalysis>>;

    abstract downloadCostAnalysisListDtoCsv(projectId: number): Observable<APIResponse<DownloadCSVUrl>>;

}
