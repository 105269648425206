import {ProjectFieldsService} from '../../fields/project-fields.service';
import {ProjectPopupService} from '../../popups/project-popup.service';
import {ProjectRendererService} from '../../renderers/project-renderer.service';
import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Polygon from '@arcgis/core/geometry/Polygon';
import {ProjectLayerData} from '../../../../../../pages/geospatial-viewer/model/project';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Injectable()
export class ProjectLayerService extends Unsubscribable {
    constructor(
        private fieldsService: ProjectFieldsService,
        private popupService: ProjectPopupService,
        private rendererService: ProjectRendererService,
    ) {
        super();
    }

    public prepareProjectLayer(data: ProjectLayerData[], currentUserId: string, layerId: string): FeatureLayer {
        const projectLayer: FeatureLayer = new FeatureLayer({
            id: layerId,
            outFields: ['*'],
            objectIdField: 'ObjectID',
            source: this.createGraphics(data, currentUserId),
            renderer: this.rendererService.customLayerRenderer(),
            fields: this.fieldsService.getCustomMapFields(),
            popupTemplate: {
                outFields: ['*'],
                content: this.popupService.getCustomPopoutFunction,
            },
        });
        return projectLayer;
    }

    /*
     * Create array of Graphics, type = polygon. Return Graphic[]
     */
    private createGraphics(data: ProjectLayerData[], currentUserId: string): Graphic[] {
        return data.map((project: ProjectLayerData) => {
            const projectGeography = JSON.parse(project.projectGeography);
            return new Graphic({
                geometry: new Polygon({
                    rings: projectGeography?.coordinates ? projectGeography.coordinates : [],
                }),
                attributes: {
                    // Unique identifiers
                    ObjectID: project.id,
                    id: project.id,
                    // Data
                    projectName: project.projectName,
                    type: project.projectCreateType,
                    status: project.projectStatus,
                    owner: project.ownerId === currentUserId ? 'me' : 'other',
                },
            });
        });
    }
}
