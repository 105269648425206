export const environment = {
    APP_VERSION_MAJOR: 2,
    APP_VERSION_MINOR: 7,
    APP_VERSION_PATCH: 0,

    PRODUCTION: false,
    STAGING: false,

    NODE_BASE_URL: 'https://api-node.test.engincloud.com',
    NODE_API_URL: 'https://api-node.test.engincloud.com/api',
    PYTHON_API_URL: 'http://3.97.230.182:9092/engin/v2/python',

    OAUTH_CLIENT_ID: 'SUJKkAYVyYhTbn7VfCYW1SCPhcxgw580fQQ54nzb',
    OAUTH_CLIENT_SECRET: 'JulWvwsbMP6cWnAFhiZCWFBxIZGWl0xtKSD49dAv',

    SSO_ENABLED: true,
    AZURE_AD: {
        AUTHORITY: 'https://login.microsoftonline.com/organizations/',
        APPLICATION_ID: 'fad46356-bfa6-49ba-a699-5a911ba0c31f',
        REDIRECT_URL: 'https://test.engincloud.com',
    },

    SENSITIVE_INFO_LOGIN: true,
    SENSITIVE_INFO_HEADER: true,
};
