<h1 id="title" class="title">Welcome</h1>
<p class="sub-title">Please log in with your username and password.</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Invalid credentials!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Success!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label" for="input-username">Username:</label>
        <input
            nbInput
            fullWidth
            [formControl]="userNameInput"
            name="username"
            id="input-username"
            pattern="[\.@\w]+"
            placeholder="Username"
            fieldSize="large"
            autofocus
            [status]="userNameInput.dirty ? (userNameInput.invalid ? 'danger' : 'success') : ''"
            [required]="true"
            [attr.aria-invalid]="userNameInput.invalid && userNameInput.touched ? true : null"
            (click)="inputBoxTouchEvent()"
        />
        <ng-container *ngIf="userNameInput.invalid && userNameInput.touched">
            <p class="caption status-danger" *ngIf="userNameInput.errors?.required">Username is required.</p>
            <p class="caption status-danger" *ngIf="userNameInput.errors?.pattern">Invalid username.</p>
        </ng-container>
    </div>

    <div class="form-control-group">
        <span class="label-with-link">
            <label class="label" for="input-password">Password:</label>
        </span>
        <input
            nbInput
            fullWidth
            [formControl]="passwordInput"
            name="password"
            type="password"
            id="input-password"
            placeholder="Password"
            fieldSize="large"
            [status]="passwordInput.dirty ? (passwordInput.invalid ? 'danger' : 'success') : ''"
            [required]="true"
            [minlength]="getConfigValue('forms.validation.password.minLength')"
            [maxlength]="getConfigValue('forms.validation.password.maxLength')"
            [attr.aria-invalid]="passwordInput.invalid && passwordInput.touched ? true : null"
            (click)="inputBoxTouchEvent()"
        />
        <ng-container *ngIf="passwordInput.invalid && passwordInput.touched">
            <p class="caption status-danger" *ngIf="passwordInput.errors?.required">Password is required.</p>
            <p class="caption status-danger" *ngIf="passwordInput.errors?.minlength || passwordInput.errors?.maxlength">
                Password should contain from {{ getConfigValue("forms.validation.password.minLength") }} to
                {{ getConfigValue("forms.validation.password.maxLength") }}
                characters.
            </p>
        </ng-container>
    </div>

    <button
        nbButton
        fullWidth
        status="primary"
        size="large"
        [disabled]="submitted || form.invalid || (requestsPending$ | async)"
        [class.btn-pulse]="submitted"
    >
        Log In
    </button>
</form>

<ng-container *ngIf="ssoOptionsEnabled">
    <section class="another-action display-flex flex-column" aria-label="SSO Azure AD">
        Alternatively, use a single sign-on provider.
    </section>
    <section class="another-action display-flex flex-column" aria-label="SSO Azure AD">
        <button
            nbButton
            fullWidth
            status="basic"
            size="large"
            (click)="ssoAuthenticate()"
            [class.btn-pulse]="submitted"
            [disabled]="requestsPending$ | async"
        >
            <nb-icon pack="engin" icon="logo-microsoft"></nb-icon>
            Log In Via Microsoft
        </button>
    </section>
</ng-container>

<section *ngIf="!ssoOptionsEnabled" class="another-action display-flex flex-column" aria-label="Forgot Password">
    <a class="text-link" routerLink="../forgot-password">Forgot Password?</a>
</section>
