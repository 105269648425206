<nb-card class="main-content-container">
    <nb-accordion [multi]="true" [formGroup]="form">
        <nb-accordion-item
            *ngFor="let section of sections"
            [disabled]="section.sections.length === 0"
            [expanded]="section.id === this.form.value.sectionId"
        >
            <nb-accordion-item-header
                [ngClass]="{'single-section': section.sections.length === 0}"
                (click)="selectSection(section.id, -1)"
            >
                {{ section.label }}
            </nb-accordion-item-header>

            <nb-accordion-item-body
                class="background-light"
                *ngFor="let subsection of section.sections"
                (click)="selectSection(section.id, subsection.id)"
            >
                <p [ngClass]="form.value.subsectionId === subsection.id ? 'selected-section' : 'section'">
                    {{ subsection.label }}
                </p>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</nb-card>
