import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '@core/backend/common/api/http.service';
import {
    GeoJSONGeometry,
    GeospatialAreaPmCountRequest,
    GeospatialAreaPmCountResponse,
    GeospatialAssetPopupRequest,
    GeospatialAssetPopupResponse,
    GeospatialGroupPopupRequest,
    GeospatialGroupPopupResponse,
    GeospatialRequest,
    GeospatialResponse,
    SpatialCustomLayerResponse,
} from '../model/api';
import {Project, ProjectCreate, ProjectDto, ProjectPopupRequest, ProjectUpdate} from '../model/project';
import {map} from 'rxjs/operators';
import {LayerType, MapLayerDetails} from '../model/layers';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class GeospatialViewerApi {
    private prefix = 'geospatial';
    private prefixProject = 'projects';

    constructor(private api: HttpService) {}

    // Core map data
    public getMapGeoData(studyId: number, req: GeospatialRequest): Observable<GeospatialResponse> {
        return this.api.post(`${this.prefix}/${studyId}`, req);
    }

    public getAssetPopupInfo(
        studyId: number,
        req: GeospatialAssetPopupRequest,
    ): Observable<GeospatialAssetPopupResponse> {
        return this.api.post(`${this.prefix}/details/asset/${studyId}`, req);
    }

    public getAssetGroupPopupInfo(
        studyId: number,
        req: GeospatialGroupPopupRequest,
    ): Observable<GeospatialGroupPopupResponse> {
        return this.api.post(`${this.prefix}/details/group/${studyId}`, req);
    }

    // Additional layers
    public getCustomMapLayer(code: string): Observable<SpatialCustomLayerResponse> {
        return this.api.get(`${this.prefix}/layers/custom/${code}`);
    }

    public getCustomMapLayerDetails(type: LayerType): Observable<MapLayerDetails[]> {
        return this.api.get(`${this.prefix}/layers/custom/${type}/details`);
    }

    // Project APIs
    public getProject(id): Observable<Project> {
        return this.api.get(`${this.prefixProject}/${id}`);
    }
    public createProject(req: ProjectCreate): Observable<APIResponse<Project>> {
        return this.api.post(`${this.prefixProject}/`, req);
    }
    public updateProject(req: ProjectUpdate): Observable<APIResponse<Project>> {
        return this.api.put(`${this.prefixProject}/${req.id}`, req);
    }
    public deleteProject(id): Observable<Project> {
        return this.api.delete(`${this.prefixProject}/${id}`);
    }
    public listProjects(filterQuery: string, pageSize?: number): Observable<Project[]> {
        const query = `?pageNumber=1&pageSize=${pageSize ? pageSize : 1000}` + (filterQuery ? `&${filterQuery}` : '');
        return this.api.get(`${this.prefixProject}/list${query}`).pipe(
            map((res) => {
                return res.items;
            }),
        );
    }
    public listProjectsByField(field: {[key: string]: any}): Observable<Project[]> {
        const key = Object.keys(field)[0];
        const value = field[key];

        const query = `?filterBy${key}=${value}&pageNumber=1&pageSize=1`;
        return this.api.get(`${this.prefixProject}/list${query}`).pipe(
            map((res) => {
                return res.items;
            }),
        );
    }
    public getProjectListDto(studyId: number, filterQuery: string, pageSize?: number): Observable<ProjectDto[]> {
        const sortQuery = '&sortBy=projectName&orderBy=asc';
        const query = `?pageNumber=1&pageSize=${pageSize}${sortQuery}` + (filterQuery ? `&${filterQuery}` : '');
        return this.api.get(`${this.prefixProject}/dto/list/study/${studyId}${query}`).pipe(
            map((res) => {
                return res.items;
            }),
        );
    }
    public getProjectPopupInfo(req: ProjectPopupRequest): Observable<APIResponse<ProjectDto>> {
        return this.api.get(`${this.prefixProject}/dto/${req.projectId}/study/${req.studyId}`);
    }
    public downloadProjectDataCsv(projectId: number, studyId: number): Observable<Record<string, string>> {
        return this.api.get(`${this.prefixProject}/download/assets/${projectId}/study/${studyId}`);
    }

    // Project support
    public queryAssetsInPolygon(studyId: number, geoJsonStr: string): Observable<{count: number}> {
        const req = {
            geoJsonGeometry: geoJsonStr,
        };
        return this.api.post(`${this.prefix}/${studyId}/count`, req);
    }
    public getSearchAssetList(req: string): Observable<any> {
        const query = `?assetId=${req}`;
        return this.api.get(`${this.prefix}/search/asset${query}`);
    }
    public getSearchAssetInfo(req: string): Observable<GeoJSONGeometry> {
        const query = `?assetId=${req}`;
        return this.api.get(`${this.prefix}/asset${query}`);
    }

    public getGeospatialMapDataByProgram(
        studyId: number,
        programId: number,
        req: GeospatialRequest,
    ): Observable<GeospatialResponse> {
        return this.api.post(`${this.prefix}/data/${studyId}/program/${programId}`, req);
    }

    public getGeospatialMapDataByProject(
        studyId: number,
        projectId: number,
        req: GeospatialRequest,
    ): Observable<GeospatialResponse> {
        return this.api.post(`${this.prefix}/data/${studyId}/project/${projectId}`, req);
    }

    public getGeospatialMapDataBySegment(
        studyId: number,
        segmentId: number,
        req: GeospatialRequest,
    ): Observable<GeospatialResponse> {
        return this.api.post(`${this.prefix}/data/${studyId}/segment/${segmentId}`, req);
    }

    public getCountOfAssetByAreaPmrequest(
        request: GeospatialAreaPmCountRequest,
    ): Observable<GeospatialAreaPmCountResponse> {
        return this.api.post(`${this.prefix}/pm/count`, request).pipe(map((data) => data.count));
    }
}
