import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ChartsService, FontsService, FormatsService} from '@core/utils';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {AnalyzerResponse} from '@core/interfaces/engin/analyzer';
import {filter, map, share} from 'rxjs/operators';
import {Chart} from '@core/utils/charts.service';
import {ECHARTS_TYPE} from '@theme/components/chart-settings/chart-data-download-service';
import {FormGroup} from '@angular/forms';
import {GraphLabel, Page, ValuePrepareModeEnum} from '@core/interfaces/common/pages';
import {GraphLabelService} from '../../graph-label.service';
import {ECharts} from 'echarts';
import {NbThemeService} from '@nebular/theme';

@Component({
    selector: 'ngx-eol-metric-summary',
    templateUrl: './eol-metric-summary.component.html',
    styleUrls: ['./eol-metric-summary.component.scss'],
})
export class EolMetricSummaryComponent implements OnInit, OnChanges {
    // Component inputs
    @Input('pieChartData') _pieChartData: AnalyzerResponse; // raw chart data
    @Input('unitOptions') _unitOptions: string[]; // unit selector options
    @Input('labelOptions') _labelOptions: GraphLabel[]; // label selector options
    @Input('graphId') graphId: string;
    @Input('graphsFormGroup') graphsFormGroup: FormGroup; // active group selection control
    @Input() totalRow: boolean = false;
    @Input() interactive: boolean = true;
    @Input() ignoreFormControl: boolean = false;
    @Input() page: Page = null;
    @Input() currentTheme: string = null;
    // Static component data
    EC_TYPE_LOCAL = ECHARTS_TYPE.PIE;
    private pieChartData2: BehaviorSubject<AnalyzerResponse> = new BehaviorSubject<AnalyzerResponse>(null);
    readonly pieChartData$: Observable<AnalyzerResponse> = this.pieChartData2.asObservable().pipe(
        filter((d) => !!d),
        share(),
    );
    public unitOptions: string[];
    public labelOptions: BehaviorSubject<GraphLabel[]> = new BehaviorSubject<GraphLabel[]>(null);
    public labelOptions$: Observable<any> = this.labelOptions.asObservable();

    public chartInstance: ECharts;

    constructor(
        private chartsService: ChartsService,
        private fontsService: FontsService,
        private nbThemeService: NbThemeService,
    ) {}

    ngOnInit(): void {
        if (this.currentTheme) {
            this.nbThemeService.changeTheme(this.currentTheme);
        }
        if (this._pieChartData && !!this._pieChartData) {
            this.pieChartData2.next(this._pieChartData);
        }
        if (this._labelOptions && !!this._labelOptions) {
            this.labelOptions.next(this._labelOptions);
        }
    }

    // Propagate external updates of main data source throughout this component
    ngOnChanges(changes: any) {
        if (changes._pieChartData != null && changes._pieChartData.currentValue != null) {
            this.pieChartData2.next(this._pieChartData);
            this.unitOptions = this._unitOptions;
            if (this._labelOptions != null) {
                this.labelOptions.next(this._labelOptions);
            }
        }
    }

    pieChart$ = combineLatest<Observable<AnalyzerResponse>, Observable<Chart>, Observable<GraphLabel[]>>([
        this.pieChartData$,
        this.chartsService.donutChartWithHighlight$,
        this.labelOptions$,
    ]).pipe(
        map(([pieChartData, pieChartTemplate, graphLabels]: [AnalyzerResponse, Chart, GraphLabel[]]) => {
            const selectedLabel = GraphLabelService.extractGraphLabel(graphLabels, pieChartData.graphId);
            const summaryChart = this.chartsService.prepareStackedPercentageChartData(
                pieChartData,
                pieChartTemplate.colorsMap,
            );
            return this.prepareDonutChartsOptions(summaryChart, pieChartTemplate, selectedLabel);
        }),
    );

    private prepareDonutChartsOptions(metadata, pieChart, selectedLabel) {
        const title = EolMetricSummaryComponent.getFullPreparedValue(metadata.highlightedValue);
        const numberSize = this.fontsService.pxToRem(window.innerWidth, 'numberSize');
        const fontSize = this.fontsService.pxToRem(window.innerWidth, 'fontSize');
        return {
            metadata: metadata,
            options: {
                ...pieChart.options,
                toolbox: {
                    show: true,
                },
                title: {
                    ...pieChart.options.title,
                    text: metadata.name,
                    subtext: metadata.highlightedValue.name,
                },
                series: [
                    {
                        ...pieChart.options.series[0],
                        markPoint: {
                            label: {
                                show: true,
                                formatter: '{b}',
                            },
                            data: [
                                {
                                    name: title[0],
                                    symbol: 'circle',
                                    itemStyle: {color: 'transparent'},
                                    x: '50%',
                                    y: '50%',
                                    label: {
                                        fontSize: numberSize,
                                        fontWeight: 'bold',
                                        color: pieChart.colorsMap['primaryFontColor'],
                                    },
                                },
                                {
                                    name: title[1],
                                    symbol: 'circle',
                                    itemStyle: {color: 'transparent'},
                                    x: '50%',
                                    y: '56%',
                                    label: {
                                        fontSize: fontSize,
                                        color: pieChart.colorsMap['hintFontColor'],
                                    },
                                },
                            ],
                        },
                        label: {
                            ...pieChart.options.series[0].label,
                            formatter: (params) => {
                                if (params.data.value > 0) {
                                    if (selectedLabel && selectedLabel === 'value-percent') {
                                        return (
                                            params.data.formattedValue + '\n(' + (params.percent - 0).toFixed(0) + '%)'
                                        );
                                    } else {
                                        return (params.percent - 0).toFixed(0) + '%';
                                    }
                                } else {
                                    return '';
                                }
                            },
                        },
                        data: metadata.values,
                    },
                ],
            },
        };
    }

    private static getFullPreparedValue(item: any): string[] {
        if (item) {
            const formatted = FormatsService.getFormattedValue(item.value, true);
            return [`${item.prefix}${formatted[0]}`, `${formatted[1]} ${item.suffix}`];
        }
        return ['', ''];
    }

    // Helper functions for legend icons
    getChartFooterIconsMap(name: string): any {
        return this.chartsService.getChartFooterIconsMap(name);
    }

    getChartFooterIconBackground(name: string): string {
        const mapItem = this.getChartFooterIconsMap(name);
        return mapItem ? mapItem.iconContainerClass : '';
    }

    getChartFooterIconName(name: string): string {
        const mapItem = this.getChartFooterIconsMap(name);
        return mapItem ? mapItem.icon : '';
    }

    // Value formatting
    getFormattedValue(item: any, graphId: string): string {
        return this.formatValue(item.value, item.prefix, item.suffix, graphId);
    }

    // Calculate the total of all items in one pie chart, and format as rounded or not (returns with prefix/suffix)
    getFormattedTotal(series: any[], graphId: string): string {
        const total = series.reduce((a, b) => a + b.value, 0.0);
        return this.formatValue(total, series[0].prefix, series[0].suffix, graphId);
    }

    private formatValue(value: number, prefix: string, suffix: string, graphId: string): string {
        let prepareMode: ValuePrepareModeEnum = ValuePrepareModeEnum.STANDARD;
        if (!this.ignoreFormControl) {
            prepareMode = FormatsService.getPrepareMethodFormGroup(this.page, graphId, this.graphsFormGroup.value);
        }

        if (prepareMode == ValuePrepareModeEnum.STANDARD || prepareMode == ValuePrepareModeEnum.CUSTOM) {
            return `${FormatsService.prepareValue(value, prefix, suffix)}`;
        } else {
            return this.formatRawValue(value, prefix, suffix);
        }
    }

    private formatRawValue(value: number, prefix: string, suffix: string) {
        const val = value.toLocaleString('en-US', {maximumFractionDigits: 0});
        const str = [val, ''].join('');

        return `${prefix}${str}${suffix}`;
    }
}
