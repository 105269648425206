import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {User, UsersService, UserType} from '@core/interfaces/common/users';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserNameValidator} from '../user-settings/user-name-validator';
import {FormatsService} from '@core/utils';
import {UserEmailValidator} from '../user-settings/user-email-validator';
import {finalize, tap} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersStore} from '@store/common/users.store';
import {BehaviorSubject} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

export enum UserSettingPageMode {
    CREATE = 'create',
    EDIT = 'edit',
    VIEW = 'view',
}

@Component({
    selector: 'ngx-profile-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() currentPasswordRequired;
    @Input() user: User;
    @Input() ownProfile: boolean;
    @Input() userType: UserType = UserType.ENGIN;

    @Output() changeUser = new EventEmitter<User>();

    UserSettingPageMode = UserSettingPageMode;
    pageMode: UserSettingPageMode = UserSettingPageMode.VIEW;
    userForm: FormGroup;
    resetPasswordForm: FormGroup;
    newUserPassword: FormGroup;
    forcePasswordForm: FormGroup;
    passwordFormReady = new BehaviorSubject<boolean>(false);

    settingsChanged = false;
    passwordChanged = false;

    constructor(
        private usersService: UsersService,
        private fb: FormBuilder,
        private toasterService: NbToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private usersStore: UsersStore,
    ) {
        super();
    }

    ngOnInit(): void {
        this.formGroupsSetting();

        if (this.pageMode !== UserSettingPageMode.CREATE) {
            this.setUserInfo(this.user);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.user) {
            this.pageMode = (this.route.snapshot.url[0].path as UserSettingPageMode) || UserSettingPageMode.VIEW;
            if (this.pageMode !== UserSettingPageMode.CREATE) {
                this.setUserInfo(this.user);
            }
        }
    }

    disableSaveBtn() {
        switch (this.pageMode) {
            case UserSettingPageMode.CREATE: {
                return !(this.userForm.valid && this.passwordFormReady.value);
            }

            case UserSettingPageMode.EDIT: {
                this.settingsChanged = !this.userForm.pristine;
                let _passwordForm = this.ownProfile ? this.resetPasswordForm : this.forcePasswordForm;
                this.passwordChanged = !_passwordForm.pristine;
                if (!this.settingsChanged && !this.passwordChanged) return true;
                if (this.settingsChanged && !this.passwordChanged) return this.userForm.invalid;
                if (!this.settingsChanged && this.passwordChanged) return !this.passwordFormReady.value;
                if (this.settingsChanged && this.passwordChanged)
                    return !(this.userForm.valid && this.passwordFormReady.value);
            }
        }
    }

    public userTypeSSO(): boolean {
        return this.userType == UserType.SSO;
    }

    goToEditMode() {
        this.router.navigate(['/user-management/user/edit'], {
            queryParams: {username: this.user.username},
            state: {goBackButton: history?.state?.goBackButton || false},
        });
    }

    goToViewMode() {
        this.router.navigate(['/user-management/user/view'], {
            queryParams: {username: this.user.username},
            state: {goBackButton: history?.state?.goBackButton || false},
        });
    }

    save() {
        this.usersService
            .create({
                ...this.userForm.value,
                ...this.newUserPassword.value,
            })
            .subscribe((resp) => {
                if (resp.success) {
                    this.toasterService.success(resp.messages[0], 'Create User');
                    this.passwordFormReady.next(false);
                    this.router.navigate(['user-management']);
                } else {
                    this.toasterService.danger(resp.errors[0], 'Create User');
                }
            });
    }

    update() {
        if (this.passwordFormReady.value) {
            this.usersStore.changeRequestLoading(true);
            if (this.ownProfile) {
                this.updatePassword()
                    .pipe(
                        tap(() => {
                            if (this.settingsChanged) this.updateUserSettings().subscribe();
                        }),
                    )
                    .subscribe(() => this.goToViewMode());
            } else {
                this.forceUpdatePassword()
                    .pipe(
                        tap(() => {
                            if (this.settingsChanged) this.updateUserSettings().subscribe();
                        }),
                    )
                    .subscribe(() => this.goToViewMode());
            }
        } else if (this.settingsChanged) this.updateUserSettings().subscribe(() => this.goToViewMode());
    }

    updateUserSettings() {
        return this.usersService.update(this.userForm.value).pipe(
            tap((resp) => {
                if (resp.success) {
                    this.toasterService.success(resp.messages[0], 'Update User');
                    //this.setUserInfo(this.user);
                    this.changeUser.emit(this.userForm.value);
                    if (this.ownProfile) this.usersStore.refreshUser().subscribe();
                } else {
                    this.toasterService.danger(resp.errors[0], 'Update User');
                }
            }),
        );
    }

    updatePassword() {
        return this.usersService
            .updatePassword(
                this.resetPasswordForm.value.currentPassword,
                this.resetPasswordForm.value.newPassword,
                this.resetPasswordForm.value.confirmPassword,
            )
            .pipe(
                finalize(() => this.usersStore.changeRequestLoading(false)),
                tap((data) => {
                    if (data.success) {
                        this.toasterService.success('Password Reset successful.', 'Change Password');
                        this.passwordFormReady.next(false);
                    } else {
                        this.toasterService.danger(data.errors[0], 'Change Password');
                    }
                }),
            );
    }

    forceUpdatePassword() {
        return this.usersService.forceUpdatePassword(this.user.id, this.forcePasswordForm.value.password).pipe(
            finalize(() => this.usersStore.changeRequestLoading(false)),
            tap((data) => {
                if (data.success) {
                    this.toasterService.success('Password reset successful.', 'Force Password Reset');
                    this.passwordFormReady.next(false);
                } else {
                    this.toasterService.danger(data.errors[0], 'Force Password Reset');
                }
            }),
        );
    }

    private setUserInfo(data) {
        this.userForm?.patchValue(data);
        this.userType = this.user.userType || UserType.ENGIN;
    }

    formGroupsSetting() {
        this.userForm = this.fb.group({
            id: null,
            firstName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
            lastName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
            username: [
                null,
                [Validators.required, Validators.minLength(5), Validators.maxLength(50)],
                UserNameValidator.createUserNameValidator(this.usersService),
            ],
            email: [
                null,
                [Validators.pattern(FormatsService.EMAIL_PATTERN)],
                UserEmailValidator.createEmailValidator(this.usersService),
            ],
            timezone: [
                this.pageMode == UserSettingPageMode.CREATE ? Intl.DateTimeFormat().resolvedOptions().timeZone : '',
                Validators.required,
            ],
            role: [null, Validators.required],
        });
        this.resetPasswordForm = this.fb.group({
            currentPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]],
        });
        this.newUserPassword = this.fb.group({
            password: ['', [Validators.required]],
            validation: [false, [Validators.required]],
        });
        this.forcePasswordForm = this.fb.group({
            password: ['', [Validators.required]],
        });
    }
}
