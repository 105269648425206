import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {DefaultFilter} from '@mominsamir/ngx-smart-table';
import {NbPopoverDirective} from '@nebular/theme';
import {Subscription} from 'rxjs';

@Component({
    template: `
        <div class="position-relative">
            <div [nbPopover]="tabs" nbPopoverPlacement="bottom">
                <input
                    nbInput
                    fullWidth
                    #input
                    placeholder="min — max"
                    [class.active]="isPopoverShown"
                    [formControl]="inputControl"
                />
                <div class="icon-wrapper fullwidth">
                    <nb-icon icon="chevron-down" status="basic"></nb-icon>
                </div>

                <div *ngIf="isPopoverShown && inputControl.value" class="icon-wrapper reset" (click)="reset()">
                    <nb-icon icon="close-outline" status="basic"></nb-icon>
                </div>
            </div>

            <ng-template #tabs>
                <nb-card
                    [style.min-width.px]="200"
                    [style.width.px]="input.getBoundingClientRect().width"
                    style="margin:0"
                >
                    <div class="flex">
                        <div class="form-field">
                            <label for="min" class="label">Min</label>
                            <input
                                nbInput
                                fullWidth
                                fieldSize="small"
                                type="number"
                                id="min"
                                autofocus
                                [formControl]="minControl"
                                (keydown.enter)="max.focus()"
                            />
                        </div>

                        <div class="form-field">
                            <label for="max" class="label">Max</label>
                            <input
                                nbInput
                                fullWidth
                                fieldSize="small"
                                type="number"
                                id="max"
                                #max
                                [formControl]="maxControl"
                                (keydown.enter)="popover.hide()"
                            />
                        </div>
                    </div>
                </nb-card>
            </ng-template>
        </div>
    `,
    styleUrls: ['numeric-range-filter.component.scss'],
    styles: [
        `
            .flex {
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: 2px;
                padding: 5px;
            }

            .form-field {
                width: 47%;
                min-width: 82px;
                margin: 5px;
            }

            label {
                font-family: Roboto, 'Helvetica Neue', sans-serif !important;
                font-weight: 500 !important;
                margin-bottom: 0.25rem;
            }
        `,
    ],
})
export class NumericRangeFilterComponent extends DefaultFilter implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

    isPopoverShown: boolean = false;

    inputControl: FormControl = new FormControl();
    minControl: FormControl = new FormControl();
    maxControl: FormControl = new FormControl();

    subscriptions: Subscription[];

    constructor() {
        super();
    }

    ngOnInit() {
        const minVal = this.column?.filter?.config?.min || 0;
        const checkMax = this.column?.filter?.config?.max != undefined;
        const maxVal = this.column?.filter?.config?.max;

        this.subscriptions = [
            this.minControl.valueChanges
                // .pipe(debounceTime(this.delay)) do we need this?
                .subscribe((value: number) => {
                    if (value !== null && !this.isInteger(value)) {
                        value = Math.floor(value);
                        this.minControl.setValue(Math.floor(value), {emitEvent: false});
                    }

                    // Check value below defined min
                    if (value !== null && value < minVal) {
                        value = minVal;
                        this.minControl.setValue(value, {emitEvent: false});
                    }

                    // Check value above defined max, if a max is defined
                    if (value !== null && checkMax && value > maxVal) {
                        value = maxVal;
                        this.minControl.setValue(value, {emitEvent: false});
                    }

                    let min = value !== null ? value + '' : 'min',
                        max = this.maxControl.value !== null ? this.maxControl.value + '' : 'max';

                    if (value !== null && this.maxControl.value !== null && this.maxControl.value < value) {
                        this.maxControl.setValue(value, {emitEvent: false});
                        max = this.maxControl.value + '';
                    }

                    let result = value == null && this.maxControl.value == null ? '' : `${min} — ${max}`;
                    this.inputControl.setValue(result, {emitEvent: false});
                }),

            this.maxControl.valueChanges
                // .pipe(debounceTime(this.delay))  do we need this?
                .subscribe((value: number) => {
                    if (value !== null && !this.isInteger(value)) {
                        value = Math.floor(value);
                        this.maxControl.setValue(Math.floor(value), {emitEvent: false});
                    }

                    // Check value below defined min
                    if (value !== null && value < minVal) {
                        value = minVal;
                        this.maxControl.setValue(value, {emitEvent: false});
                    }

                    // Check value above defined max, if a max is defined
                    if (value !== null && checkMax && value > maxVal) {
                        value = maxVal;
                        this.maxControl.setValue(value, {emitEvent: false});
                    }

                    let min = this.minControl.value !== null ? this.minControl.value + '' : 'min',
                        max = value !== null ? value + '' : 'max';

                    if (value !== null && this.minControl.value !== null && this.minControl.value > value) {
                        this.minControl.setValue(value, {emitEvent: false});
                        min = this.minControl.value + '';
                    }

                    let result = value == null && this.minControl.value == null ? '' : `${min} — ${max}`;
                    this.inputControl.setValue(result, {emitEvent: false});
                }),

            this.inputControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value: string) => {
                if (value == null) {
                    this.query = '';
                    this.setFilter();
                } else if (this.minControl.value != null || this.maxControl.value != null) {
                    if (this.column.filter.config && this.column.filter.config.type) {
                        this.numberType(this.column.filter.config.type);
                    } else {
                        // Do not use '||' operator here, since real value of 0 is treated as "false" and will be ignored
                        this.query =
                            (this.minControl.value == null ? this.maxControl.value : this.minControl.value) +
                            '|' +
                            (this.maxControl.value == null ? this.minControl.value : this.maxControl.value);
                    }
                    this.setFilter();
                }
            }),
        ];
    }

    numberType(type) {
        switch (type) {
            case 'fraction':
                let min = (this.minControl.value === null ? this.maxControl.value : this.minControl.value) / 100;
                let max = (this.maxControl.value === null ? this.minControl.value : this.maxControl.value) / 100;
                this.query = min + '|' + max;
                break;
            default:
                break;
        }
    }

    ngAfterViewInit() {
        this.subscriptions.push(
            this.popover?.nbPopoverShowStateChange.subscribe(({isShown}) => {
                if (!this.popover.isShown) {
                    this.isPopoverShown = false;
                    this.completeValues();
                } else {
                    this.isPopoverShown = true;
                }
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.inputControl.setValue(this.inputControl.value);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    completeValues() {
        if (this.minControl.value === null) this.minControl.setValue(this.maxControl.value);

        if (this.maxControl.value === null) this.maxControl.setValue(this.minControl.value);

        if (this.minControl.value === null && this.maxControl.value === null) {
            this.inputControl.reset();
        } else {
            let result = `${this.minControl.value} — ${this.maxControl.value}`;

            this.inputControl.setValue(result);
        }
    }

    reset() {
        this.minControl.reset();
        this.maxControl.reset();
    }

    isInteger(num: number) {
        return (num ^ 0) === num;
    }
}
