<nb-card size="small" [ngClass]="[widthSize]">
    <nb-card-header>
        <div class="header">
            {{ title }}

            <button ghost status="basic" nbButton (click)="close()">
                <nb-icon icon="close" status="basic"></nb-icon>
            </button>
        </div>

        <div class="row">
            <ng-container *ngIf="!headerInfo; else customHeaderInfo">
                <div class="col-4">
                    <span class="col-form-label">Version:</span>
                    <span class="col-form-data">{{ data.version || "—" }}</span>
                </div>
                <div class="col-8">
                    <span class="col-form-label">Created On:</span>
                    <span class="col-form-data">{{ data.createdOn || "—" }}</span>
                </div>
            </ng-container>
        </div>
    </nb-card-header>

    <nb-card-body *ngIf="content" [nbSpinner]="!(_context | async)" nbSpinnerSize="medium" nbSpinnerStatus="primary">
        <ng-container *ngIf="_context | async as data">
            <ng-container *ngTemplateOutlet="content; context: {$implicit: data}"></ng-container>
        </ng-container>
    </nb-card-body>

    <nb-card-footer>
        <button nbButton status="basic" (click)="close()">
            <nb-icon icon="close-outline"></nb-icon>
            Cancel
        </button>
        <button nbButton status="primary" (click)="confirmDelete()" [disabled]="!(_context | async) || disableDelete()">
            <nb-icon icon="trash-2-outline"></nb-icon>
            Proceed
        </button>
    </nb-card-footer>
</nb-card>

<ng-template #customHeaderInfo>
    <div
        [ngClass]="columnHeaderInfo ? 'col-12' : even ? 'col-4' : 'col-8'"
        *ngFor="let item of headerInfo; let even = even"
    >
        <span class="col-form-label">{{ item.label }}</span>
        <span class="col-form-data">{{ item.value }}</span>
    </div>
</ng-template>
