import {StudiesStore} from '@store/common/studies.store';
import {GeospatialViewerService} from '../../../../../pages/geospatial-viewer/api/geospatial-viewer.service';
import {ProjectDto, ProjectPopupRequest} from '../../../../../pages/geospatial-viewer/model/project';
import {Injectable} from '@angular/core';
import {UsersStore} from '@store/common/users.store';
import {filter} from 'rxjs/operators';
import {User} from '@core/interfaces/common/users';
import {WorkflowType} from '@core/interfaces/engin/workflow';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class ProjectPopupService {
    /*
     * Render popup for road closure feature. Works for point or line features equivalently.
     */
    constructor(
        private studiesStore: StudiesStore,
        private geospatialViewerService: GeospatialViewerService,
        private userStore: UsersStore,
    ) {}
    getCustomPopoutFunction = (feature) => {
        // From feature
        const projectId: string = feature.graphic.attributes.ObjectID;

        // From stores
        const popupRequest: ProjectPopupRequest = {
            projectId: projectId,
            studyId: this.studiesStore.getActiveStudyIdByType(WorkflowType.STUDY),
        };
        let currentUserId;
        this.userStore.currentUser$.pipe(filter((u: User) => u && !!u)).subscribe((x) => {
            currentUserId = x.id;
        });
        return this.geospatialViewerService
            .getProjectPopupInfo(popupRequest)
            .toPromise()
            .then((res: APIResponse<ProjectDto>) => {
                return this.renderProjectPopup(feature, res.response, currentUserId);
            });
    };

    renderProjectPopup(feature: any, project: ProjectDto, currentUserId: string) {
        const div = document.createElement('div');

        const owner = project.ownerId === currentUserId ? 'me' : project.ownerName;

        // layer = 'c' is street view; 'cbll' is lat/long for streetview; 'll' is lat/long for default map
        const lat = feature?.graphic?.geometry?.centroid?.latitude;
        const long = feature?.graphic?.geometry?.centroid?.longitude;
        const streetViewUrl = `http://maps.google.com/maps?layer=c&cbll=${lat},${long}`;
        const mapsUrl = `http://maps.google.com/maps?ll=${lat},${long}`;
        const extraHtml =
            lat && long
                ? `
        <div class='row' style='padding-top:16px'>
            <p>External links (opens new tab)</p>
        </div>
        <div class='row'>
            <a class='col-6' href="${streetViewUrl}" target="_blank">
              Google Street View (centroid)
            </a>
            <a class='col-6' href="${mapsUrl}" target="_blank">
              Google Maps (centroid)
            </a>
        </div>
        `
                : '';

        div.innerHTML = `<div class='container'>
                         <div class="row projectPopup">
                            <div class="col">
                            <p>${project.projectName}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col projectPopup">
                            <p style="font-size: 12px; margin-bottom: 8px;">Status: ${project.projectStatus}</p>
                            <p style="font-size: 12px; margin-bottom: 8px;">Program: ${project.programName}</p>
                            <p style="font-size: 12px; margin-bottom: 8px;">Owner: ${owner}</p>
                            </div>
                        </div>
                        ${extraHtml}
                      </div>`;
        return div;
    }
}
